import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CataleanStorageService } from 'catalean-storage';
import { BehaviorSubject, from, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CataleanLocalizationService {
  private _language = new BehaviorSubject<string>('');

  constructor(private translateService: TranslateService, private storage: CataleanStorageService) {
    this.translateService.onLangChange.subscribe((lan) => this._language.next(lan.lang));
    this.checkSavedLang().pipe(first()).subscribe();
  }

  private checkSavedLang() {
    return from(this.storage.getLanguage()).pipe(
      switchMap((language) => {
        let al = language
        if(!al) {
          al = this.DefaultLanguage
        }
        return this.translateService.use(language).pipe(
          map(() => {
            this._language.next(language);
            return language;
          })
        )
      }
    ),
      catchError(() => {
        this._language.next(this.translateService.defaultLang);
        return of(this.translateService.defaultLang);
      })
    );
  }

  init() {
    return this.checkSavedLang();
  }

  get ActiveLanguage() {
    return this._language.value;
  }

  get ObserveActiveLang() {
    return this._language.asObservable();
  }

  get DefaultLanguage() {
    return this.translateService.defaultLang;
  }

  setDefaultLang(language: string) {
    return this.translateService.setDefaultLang(language);
  }

  setActiveLanguage(language: string) {
    this.storage.setLanguage(language);
    return this.translateService.use(language);
  }

  get AvailableLocales() {
    return this.translateService.getLangs();
  }

  set AvailableLocales(languages: string[]) {
    this.translateService.addLangs(languages);
  }
}
