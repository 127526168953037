<ion-app>
  <ion-menu side="end" menuId="catalean-menu" (ionWillOpen)="this.onMenuOpen()" contentId="menu">
    <cat-download-progress *ngIf="this.platform.is('cordova')" [update]="openMenuSubject" [width]="80" class="download-container">
      <smart-icon name="t" type="pause"></smart-icon>
      <smart-icon name="u" type="play"></smart-icon>
    </cat-download-progress>
    <cat-hamburger-menu
      [rootFilter]="rootFilter$ | async"
      *ngIf="rootFilter$ | async as rootFilter"
      [onMenuOpen]="openMenuSubject"
      (filterSelected)="goTo($event)"
      (clickLogout)="logout()"
      [rootFilter]="rootFilter"
      [filters]="rootFilterChildren$ | async"
    >
    </cat-hamburger-menu>
    <cat-localization-selector></cat-localization-selector>
    <div class="app-version">
      <span>VERSION {{ version }}</span>
    </div>
  </ion-menu>
  <div class="mobile" *ngIf="isMobile$ | async">
    <img class="logo" src="./assets/images/logo-deka.png" />
    <div class="mobile-message">
      <img class="image" src="./assets/images/min-width.png" />
      <h2>{{ "NO_MOBILE.HEADER" | translate }}</h2>
      <p>{{ "NO_MOBILE.CONTENT" | translate }}</p>
    </div>
  </div>
  <ion-router-outlet [swipeGesture]="false" id="menu"></ion-router-outlet>
</ion-app>
